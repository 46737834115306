import Vue from "vue";
import VueRouter from "vue-router";
import PageMainLanding from "@/views/main/PageMainLanding.vue";
import PageMainHome from "@/views/main/PageMainHome.vue";
import PageMainDiscover from "@/views/main/PageMainDiscover.vue";
import LayoutBlank from "../layouts/LayoutBlank.vue";
import LayoutMain from "../layouts/LayoutMain.vue";
import LayoutConsole from "../layouts/LayoutConsole.vue";

//BOC:[state]
import store from "./../store/index";
//EOC
//BOC:[guard]
import multiguard from "vue-router-multiguard";
const loggedIn = function (to, from, next) {
  if (!store.state.auth.data) next({ name: "PageMainDiscover" });
  next();
};
const notLoggedIn = function (to, from, next) {
  if (store.state.auth.data && store.state.auth.data.Session)
    next({ name: "PageMainDiscover" });
  next();
};
const isAdmin = function (to, from, next) {
  if (store.state.auth.data.Account.role != "admin")
    next({ name: "PageMainDiscover" });
  next();
};
const isModerator = function (to, from, next) {
  if (store.state.auth.data.Account.type != "moderator")
    next({ name: "PageMainDiscover" });
  next();
};
// const isTeacher = function (to, from, next) {
//   if (store.state.auth.data.Account.role != "teacher")
//     next({ name: "PageMainDiscover" });
//   next();
// };
const isVerifiedMobile = function (to, from, next) {
  if (store.state.auth.data.Account.mobile && !store.state.auth.data.Account.isVerifiedMobile)
    next({ name: "PageEducatorAccountVerify" });
  next();
};

const isChangedPassword = function (to, from, next) {
  if (store.state.auth.data.Account.mobile && !store.state.auth.data.Account.isChangedPassword)
    next({ name: "PageEducatorOnboardPassword" });
  next();
};

// const hasTelegramUid = function (to, from, next) {
//   if (store.state.auth.data.Account.mobile && !store.state.auth.data.Account.hasTelegramUid)
//     next({ name: "PageEducatorOnboardTelegram" });
//   next();
// };
//EOC

const isFamily = function (to, from, next) {
  if (store.state.auth.data.Account.type != "family")
    next({ name: "PageMainDiscover" });
  next();
};

const isChild = function (to, from, next) {
  if (store.state.auth.data.User.type != "child")
    next({ name: "PageFamilyDashboard" });
  next();
};

const isEducator = function (to, from, next) {
  if (store.state.auth.data.Account.type != "educator")
    next({ name: "PageMainDiscover" });
  next();
};

Vue.use(VueRouter);

//BOC:[locale]
//var locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1')
var pathArray = window.location.pathname.split("/");
var locale = pathArray[1];
var path2 = pathArray[2];
if (locale != "" && (path2 == null || path2 == "")) {
  window.location.href = window.location.origin;
}
//EOC

const routes = [
  {
    path: "/",
    component: LayoutBlank,
    children: [
      {
        path: "",
        name: "PageMainLanding",
        component: PageMainLanding,
      },
    ],
  },
  {
    path: "/",
    component: LayoutMain,
    children: [
      {
        path: "home",
        name: "PageMainHome",
        component: PageMainHome,
        //redirect: { name: 'PageMainDiscover' },
        meta: {
          title: "Home",
        },
      },
      {
        path: "products/town",
        name: "PageMainProductTown",
        component: () => import("@/views/main/PageMainProductTown.vue"),
        props: true,
      },
      {
        path: "discover",
        name: "PageMainDiscover",
        component: PageMainDiscover,
        meta: {
          title: "Discover",
        },
      },
      {
        path: "quiz/:code",
        name: "PageMainDiscoverQuiz",
        component: () => import("@/views/main/PageMainDiscoverQuiz.vue"),
        props: true,
      },
      // {
      //   path: 'pages/220901',
      //   component: () =>
      //     import ('@/views/main/pages/220901.vue')
      // },
      {
        path: "pages/troubleshoot",
        name: "PageMainPageTroubleshoot",
        component: () => import("@/views/main/pages/troubleshoot.vue"),
      },
      {
        path: "events",
        name: "PageMainEvent",
        component: () => import("@/views/main/PageMainEvent.vue"),
      },
      {
        path: "events/:eventCode",
        name: "PageMainEventScoreboard",
        component: () => import("@/views/main/PageMainEventScoreboard.vue"),
      },
      {
        path: "events/:eventCode/finishers/:schoolCode",
        name: "PageMainEventFinisher",
        component: () => import("@/views/main/PageMainEventFinisher.vue"),
      },
      {
        path: "events/:eventCode/guide",
        name: "PageMainEventGuide",
        component: () => import("@/views/main/PageMainEventGuide.vue"),
      },
      {
        path: "events/:eventCode/followup",
        name: "PageMainEventFollowup",
        component: () => import("@/views/main/PageMainEventFollowup.vue"),
      },
      {
        path: "events/:eventCode/analysis",
        name: "PageMainEventAnalysis",
        component: () => import("@/views/main/PageMainEventAnalysis.vue"),
      },
      {
        path: "discover/chapter/:chapterkey/question/:code",
        name: "PageMainDiscoverSample",
        component: () => import("@/views/main/PageMainDiscoverSample.vue"),
        props: true,
      },
      {
        path: "register",
        name: "PageMainRegister",
        beforeEnter: multiguard([notLoggedIn]),
        component: () => import("@/views/main/PageMainRegister.vue"),
      },
      {
        path: "apply",
        name: "PageMainApply",
        beforeEnter: multiguard([notLoggedIn]),
        component: () => import("@/views/main/PageMainApply.vue"),
      },
      {
        path: "login",
        name: "PageMainLogin",
        beforeEnter: multiguard([notLoggedIn]),
        component: () => import("@/views/main/PageMainLogin.vue"),
      },
      {
        path: "teacher/invitation",
        name: "PageEducatorInstitutionTeacherInvite",
        component: () => import("@/views/educator/PageEducatorInstitutionTeacherInvite.vue"),
      },
      {
        path: "account/forgotPassword",
        name: "PageAccountForgetPassword",
        component: () => import("@/views/account/PageAccountForgetPassword.vue"),
      },
      {
        path: "account/resetPassword",
        name: "PageAccountPasswordReset",
        component: () => import("@/views/account/PageAccountPasswordReset.vue"),
      },
      {
        path: "language",
        name: "PageMainLanguage",
        component: () => import("@/views/main/PageMainLanguage.vue"),
      },
      {
        path: "news",
        name: "PageMainNews",
        component: () => import("@/views/main/PageMainNews.vue"),
      },
      {
        path: "help",
        name: "PageMainHelp",
        component: () => import("@/views/main/PageMainHelp.vue"),
      },
      {
        path: "supportUs",
        name: "PageMainSupportUs",
        component: () => import("@/views/main/PageMainSupportUs.vue"),
      },
      {
        path: "logout",
        name: "PageMainLogout",
        component: () => import("@/views/main/PageMainLogout.vue"),
      },
      {
        path: "events/:eventCode/students/:mochiId",
        name: "PageMainEventScoreboardStudent",
        component: () =>
          import("@/views/main/PageMainEventScoreboardStudent.vue"),
      },
      {
        path: "festivals/:festivalCode",
        name: "PageMainFestivalRead",
        component: () => import("@/views/main/PageMainFestivalRead.vue"),
      },
      {
        path: "festivals/:festivalCode/events/:eventCode/students/:studentId",
        name: "PageMainFestivalScoreboardStudent",
        component: () =>
          import("@/views/main/PageMainFestivalScoreboardStudent.vue"),
      },
      {
        path: "festivals",
        name: "PageMainFestival",
        component: () => import("@/views/main/PageMainFestival.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: LayoutConsole,
    beforeEnter: multiguard([loggedIn, isAdmin]),
    children: [
      {
        path: "dashboard",
        name: "PageAdminDashboard",
        component: () => import("@/views/admin/PageAdminDashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "data/:object",
        name: "PageAdminDataBrowse",
        component: () => import("@/views/common/PageBreadBrowse.vue"),
        meta: {
          title: "Data",
        },
        props: (route) => ({ role: "Admin", ...route.params }),
      },
      {
        path: "data/:object/redirect",
        name: "PageAdminDataBrowseRedirect",
        component: () => import("@/views/common/PageBreadBrowseRedirect.vue"),
        props: (route) => ({ role: "Admin", ...route.params }),
      },
      {
        path: "data/:object/new",
        name: "PageAdminDataAdd",
        component: () => import("@/views/common/PageBreadAdd.vue"),
        meta: {
          title: "New Data",
        },
        props: (route) => ({ role: "Admin", ...route.params }),
      },
      {
        path: "data/:object/:id/edit",
        name: "PageAdminDataEdit",
        component: () => import("@/views/common/PageBreadEdit.vue"),
        meta: {
          title: "Edit Data",
        },
        props: (route) => ({ role: "Admin", ...route.params }),
      },
      {
        path: "data/:object/:id/delete",
        name: "PageAdminDataDelete",
        component: () => import("@/views/common/PageBreadDelete.vue"),
        meta: {
          title: "Delete Data",
        },
        props: (route) => ({ role: "Admin", ...route.params }),
      },
      {
        path: "data/:object/:id",
        name: "PageAdminDataRead",
        component: () => import("@/views/common/PageBreadRead.vue"),
        meta: {
          title: "Data Info",
        },
        props: (route) => ({ role: "Admin", ...route.params }),
      },
    ],
  },
  {
    path: "/moderator",
    component: LayoutConsole,
    beforeEnter: multiguard([loggedIn, isModerator]),
    children: [
      {
        path: "classrooms",
        name: "PageModeratorClassroomBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomBrowse.vue"),
        meta: {
          title: "Classrooms",
        },
      },
      {
        path: "applications",
        name: "PageModeratorApplicationBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorApplicationBrowse.vue"),
        meta: {
          title: "Applications",
        },
      },
      {
        path: "applications/:id",
        name: "PageModeratorApplicationRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorApplicationRead.vue"),
        meta: {
          title: "Application Info",
        },
      },
      {
        path: "applications/:id/approve",
        name: "PageModeratorApplicationApprove",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorApplicationApprove.vue"),
        meta: {
          title: "Approve Application",
        },
      },
      {
        path: "applications/:id/reject",
        name: "PageModeratorApplicationReject",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorApplicationReject.vue"),
        meta: {
          title: "Reject Application",
        },
      },
      {
        path: "classrooms/new",
        name: "PageModeratorClassroomAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomAdd.vue"),
        meta: {
          title: "New Classroom",
        },
      },
      {
        path: "classrooms/:parentId/assignSchoolYear",
        name: "PageModeratorEventClassroomOnEventAssignSchool",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorEventClassroomOnEventAssignSchool.vue"
          ),
        meta: {
          title: "New Classroom",
        },
      },
      {
        path: "classrooms/:parentId/assignOtherSchool",
        name: "PageModeratorEventClassroomOnEventAssignOtherSchool",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorEventClassroomOnEventAssignOtherSchool.vue"
          ),
        meta: {
          title: "New Classroom",
        },
      },
      {
        path: "classrooms/:id/edit",
        name: "PageModeratorClassroomEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomEdit.vue"),
        meta: {
          title: "Edit Classroom",
        },
      },
      {
        path: "classrooms/:id/delete",
        name: "PageModeratorClassroomDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomDelete.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "classrooms/:id/deleteHard",
        name: "PageModeratorClassroomDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomDeleteHard.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "classrooms/:id/restore",
        name: "PageModeratorClassroomRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomRestore.vue"),
        meta: {
          title: "Restore Classroom",
        },
      },
      {
        path: "classrooms/:id",
        name: "PageModeratorClassroomRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomRead.vue"),
        meta: {
          title: "Classroom Info",
        },
      },
      {
        path: "users",
        name: "PageModeratorUserBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserBrowse.vue"),
        meta: {
          title: "Users",
        },
      },
      {
        path: "users/new",
        name: "PageModeratorUserAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserAdd.vue"),
        meta: {
          title: "New User",
        },
      },
      {
        path: "users/:id",
        name: "PageModeratorUserRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserRead.vue"),
        meta: {
          title: "User Info",
        },
      },
      {
        path: "users/:id/edit",
        name: "PageModeratorUserEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserEdit.vue"),
        meta: {
          title: "Edit User",
        },
      },
      {
        path: "users/:id/delete",
        name: "PageModeratorUserDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserDelete.vue"),
        meta: {
          title: "Delete User",
        },
      },
      {
        path: "users/:id/deleteHard",
        name: "PageModeratorUserDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserDeleteHard.vue"),
        meta: {
          title: "Delete User",
        },
      },
      {
        path: "users/:id/restore",
        name: "PageModeratorUserRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorUserRestore.vue"),
        meta: {
          title: "Restore User",
        },
      },
      {
        path: "classrooms/:parentId/students/new",
        name: "PageModeratorClassroomStudentAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomStudentAdd.vue"),
        meta: {
          title: "New Student",
        },
      },
      {
        path: "classrooms/:parentId/managePass",
        name: "PageModeratorClassroomStudentExpressPassManage",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorClassroomStudentExpressPassManage.vue"
          ),
        meta: {
          title: "Manage Express Pass",
        },
      },
      {
        path: "classrooms/:parentId/managePass/generate",
        name: "PageModeratorClassroomExpressGenerate",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomExpressGenerate.vue"),
        meta: {
          title: "Generate Express Pass",
        },
      },
      {
        path: "classrooms/:parentId/students/:id/edit",
        name: "PageModeratorClassroomStudentEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomStudentEdit.vue"),
        meta: {
          title: "Edit Student",
        },
      },
      {
        path: "classrooms/:parentId/students/:id/delete",
        name: "PageModeratorClassroomStudentDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomStudentDelete.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "classrooms/:parentId/students/:id",
        name: "PageModeratorClassroomStudentRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorClassroomStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "dashboard",
        name: "PageModeratorDashboard",
        component: () => import("@/views/moderator/PageModeratorDashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      // {
      //   path: 'data/:object',
      //   name: 'PageModeratorDataBrowse',
      //   component: () =>
      //     import ('@/views/common/PageBreadBrowse.vue'),
      //   meta: {
      //     title: 'Data',
      //   },
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      // {
      //   path: 'data/:object/redirect',
      //   name: 'PageModeratorDataBrowseRedirect',
      //   component: () =>
      //     import ('@/views/common/PageBreadBrowseRedirect.vue'),
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      // {
      //   path: 'data/:object/:id',
      //   name: 'PageModeratorDataRead',
      //   component: () =>
      //     import ('@/views/common/PageBreadRead.vue'),
      //   meta: {
      //     title: 'Data Info',
      //   },
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      // {
      //   path: 'data/:object/new',
      //   name: 'PageModeratorDataAdd',
      //   component: () =>
      //     import ('@/views/common/PageBreadAdd.vue'),
      //   meta: {
      //     title: 'New Data',
      //   },
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      // {
      //   path: 'data/:object/:id/edit',
      //   name: 'PageModeratorDataEdit',
      //   component: () =>
      //     import ('@/views/common/PageBreadEdit.vue'),
      //   meta: {
      //     title: 'Edit Data',
      //   },
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      // {
      //   path: 'data/:object/:id/delete',
      //   name: 'PageModeratorDataDelete',
      //   component: () =>
      //     import ('@/views/common/PageBreadDelete.vue'),
      //   meta: {
      //     title: 'Delete Data',
      //   },
      //   props: route => ({ role: 'Moderator', ...route.params, }),
      // },
      {
        path: "finishers",
        name: "PageModeratorFinisherBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFinisherBrowse.vue"),
        meta: {
          title: "Finishers",
        },
      },
      {
        path: "finishers/list",
        name: "PageModeratorFinisherList",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFinisherList.vue"),
        meta: {
          title: "Finishers List",
        },
      },
      {
        path: "finishers/:id",
        name: "PageModeratorFinisherRead",
        props: true,
        component: () => import("@/views/moderator/PageModeratorFinisherRead.vue"),
        meta: {
          title: "Finisher Info",
        },
      },
      {
        path: "finishers/:id/delete",
        name: "PageModeratorFinisherDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFinisherDelete.vue"),
        meta: {
          title: "Delete Finisher",
        },
      },
      {
        path: "finishers/:id/edit",
        name: "PageModeratorFinisherEdit",
        props: true,
        component: () => import("@/views/moderator/PageModeratorFinisherEdit.vue"),
        meta: {
          title: "Edit Finisher",
        },
      },
      {
        path: "series",
        name: "PageModeratorSeriesBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesBrowse.vue"),
        meta: {
          title: "Series",
        },
      },
      {
        path: "series/new",
        name: "PageModeratorSeriesAdd",
        props: true,
        component: () => import("@/views/moderator/PageModeratorSeriesAdd.vue"),
        meta: {
          title: "New Series",
        },
      },
      {
        path: "series/:id/edit",
        name: "PageModeratorSeriesEdit",
        props: true,
        component: () => import("@/views/moderator/PageModeratorSeriesEdit.vue"),
        meta: {
          title: "Edit Series",
        },
      },
      {
        path: "series/:id",
        name: "PageModeratorSeriesRead",
        props: true,
        component: () => import("@/views/moderator/PageModeratorSeriesRead.vue"),
        meta: {
          title: "Series Info",
        },
      },
      {
        path: "series/:id/delete",
        name: "PageModeratorSeriesDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesDelete.vue"),
        meta: {
          title: "Delete Series",
        },
      },
      {
        path: "series/:parentId/sponsors/new",
        name: "PageModeratorSeriesSeriesSponsorAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesSeriesSponsorAdd.vue"),
        meta: {
          title: "New Sponsor",
        },
      },
      {
        path: "series/:parentId/sponsors/:id",
        name: "PageModeratorSeriesSeriesSponsorRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesSeriesSponsorRead.vue"),
        meta: {
          title: "Sponsor Info",
        },
      },
      {
        path: "series/:parentId/sponsor/:id/delete",
        name: "PageModeratorSeriesSeriesSponsorDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesSeriesSponsorDelete.vue"),
        meta: {
          title: "Delete Sponsor",
        },
      },
      {
        path: "series/:parentId/sponsors/:id/edit",
        name: "PageModeratorSeriesSeriesSponsorEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSeriesSeriesSponsorEdit.vue"),
        meta: {
          title: "Edit Sponsor",
        },
      },
      {
        path: "events",
        name: "PageModeratorEventBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventBrowse.vue"),
        meta: {
          title: "Events",
        },
      },
      {
        path: "events/new",
        name: "PageModeratorEventAdd",
        props: true,
        component: () => import("@/views/moderator/PageModeratorEventAdd.vue"),
        meta: {
          title: "New Event",
        },
      },
      {
        path: "events/:id/edit",
        name: "PageModeratorEventEdit",
        props: true,
        component: () => import("@/views/moderator/PageModeratorEventEdit.vue"),
        meta: {
          title: "Edit Event",
        },
      },
      {
        path: "events/:id",
        name: "PageModeratorEventRead",
        props: true,
        component: () => import("@/views/moderator/PageModeratorEventRead.vue"),
        meta: {
          title: "Event Info",
        },
      },
      {
        path: "events/:id/delete",
        name: "PageModeratorEventDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventDelete.vue"),
        meta: {
          title: "Delete Event",
        },
      },

      // {
      //   path: 'events/:parentId/checkpoints/new',
      //   name: 'PageModeratorEventCheckpointAdd',
      //   props: true,
      //   component: () =>
      //     import ('@/views/moderator/PageModeratorEventCheckpointAdd.vue'),
      //   meta: {
      //     title: 'New Checkpoint',
      //   },
      // },
      {
        path: "events/:parentId/rules/new",
        name: "PageModeratorEventEventRuleAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventRuleAdd.vue"),
        meta: {
          title: "New Rule",
        },
      },
      {
        path: "events/:parentId/rules/:id",
        name: "PageModeratorEventEventRuleRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventRuleRead.vue"),
        meta: {
          title: "Rule Info",
        },
      },
      {
        path: "events/:parentId/rules/:id/delete",
        name: "PageModeratorEventEventRuleDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventRuleDelete.vue"),
        meta: {
          title: "Delete Rule",
        },
      },
      {
        path: "events/:parentId/rules/:id/edit",
        name: "PageModeratorEventEventRuleEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventRuleEdit.vue"),
        meta: {
          title: "Edit Rule",
        },
      },
      {
        path: "events/:parentId/schoolYear/new",
        name: "PageModeratorEventEventSchoolYearAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSchoolYearAdd.vue"),
        meta: {
          title: "New School Year",
        },
      },
      {
        path: "events/:parentId/schoolYear/:id",
        name: "PageModeratorEventEventSchoolYearRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSchoolYearRead.vue"),
        meta: {
          title: "School Year Info",
        },
      },
      {
        path: "events/:parentId/schoolYear/:id/delete",
        name: "PageModeratorEventEventSchoolYearDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSchoolYearDelete.vue"),
        meta: {
          title: "Delete School Year",
        },
      },
      {
        path: "events/:parentId/sponsors/new",
        name: "PageModeratorEventEventSponsorAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSponsorAdd.vue"),
        meta: {
          title: "New Sponsor",
        },
      },
      {
        path: "events/:parentId/sponsors/:id",
        name: "PageModeratorEventEventSponsorRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSponsorRead.vue"),
        meta: {
          title: "Sponsor Info",
        },
      },
      {
        path: "events/:parentId/sponsor/:id/delete",
        name: "PageModeratorEventEventSponsorDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSponsorDelete.vue"),
        meta: {
          title: "Delete Sponsor",
        },
      },
      {
        path: "events/:parentId/sponsors/:id/edit",
        name: "PageModeratorEventEventSponsorEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSponsorEdit.vue"),
        meta: {
          title: "Edit Sponsor",
        },
      },
      {
        path: "events/:parentId/finisher/list",
        name: "PageModeratorEventEventFinisherList",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventFinisherList.vue"),
        meta: {
          title: "Finishers List",
        },
      },
      {
        path: "events/:parentId/finisher/sent",
        name: "PageModeratorEventEventFinisherGiftSent",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventFinisherGiftSent.vue"),
        meta: {
          title: "Sent Gift",
        },
      },
      {
        path: "events/:parentId/finishers/:id",
        name: "PageModeratorEventEventFinisherRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventFinisherRead.vue"),
        meta: {
          title: "Finisher Info",
        },
      },
      {
        path: "events/:parentId/finishers/:id/delete",
        name: "PageModeratorEventEventFinisherDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventFinisherDelete.vue"),
        meta: {
          title: "Delete Finisher",
        },
      },
      {
        path: "events/:parentId/finishers/:id/edit",
        name: "PageModeratorEventEventFinisherEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventFinisherEdit.vue"),
        meta: {
          title: "Edit Finisher",
        },
      },
      // {
      //   path: "events/:parentId/topics/manage",
      //   name: "PageModeratorEventEventTopicManage",
      //   props: true,
      //   component: () =>
      //     import("@/views/moderator/PageModeratorEventEventTopicManage.vue"),
      //   meta: {
      //     title: "New Topic",
      //   },
      // },
      {
        path: "events/:grandParentId/schoolYear/:parentId/topics/manage",
        name: "PageModeratorEventEventSchoolYearTopicManage",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventSchoolYearTopicManage.vue"),
        meta: {
          title: "New Topic",
        },
      },
      {
        path: "events/:parentId/checkpoints/template",
        name: "PageModeratorEventCheckpointTemplate",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventCheckpointTemplate.vue"),
        meta: {
          title: "New Checkpoint",
        },
      },
      {
        path: "events/:parentId/checkpoints/:id/delete",
        name: "PageModeratorEventCheckpointDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventCheckpointDelete.vue"),
        meta: {
          title: "Delete Checkpoint",
        },
      },
      {
        path: "events/:parentId/checkpoints/:id",
        name: "PageModeratorEventCheckpointRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventCheckpointRead.vue"),
        meta: {
          title: "Checkpoint Info",
        },
      },
      {
        path: "events/:parentId/prizes/manage",
        name: "PageModeratorEventEventPrizeManage",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventEventPrizeManage.vue"),
        meta: {
          title: "Manage prize",
        },
      },

      {
        path: "events/:parentId/classrooms/new",
        name: "PageModeratorEventClassroomOnEventAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorEventClassroomOnEventAdd.vue"),
        meta: {
          title: "New classroom",
        },
      },
      {
        path: "festivals",
        name: "PageModeratorFestivalBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalBrowse.vue"),
        meta: {
          title: "Festivals",
        },
      },
      {
        path: "festivals/new",
        name: "PageModeratorFestivalAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalAdd.vue"),
        meta: {
          title: "New Festival",
        },
      },
      {
        path: "festivals/:id",
        name: "PageModeratorFestivalRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalRead.vue"),
        meta: {
          title: "Festival Info",
        },
      },
      {
        path: "festivals/:id/edit",
        name: "PageModeratorFestivalEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalEdit.vue"),
        meta: {
          title: "Edit Festival",
        },
      },
      {
        path: "festivals/:id/delete",
        name: "PageModeratorFestivalDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalDelete.vue"),
        meta: {
          title: "Delete Festival",
        },
      },
      {
        path: "festivals/:parentId/schools/new",
        name: "PageModeratorFestivalFestivalSchoolAdd",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalSchoolAdd.vue"
          ),
        meta: {
          title: "Assign School",
        },
      },
      {
        path: "festivals/:parentId/sponsors/new",
        name: "PageModeratorFestivalFestivalSponsorAdd",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalSponsorAdd.vue"
          ),
        meta: {
          title: "New sponsor",
        },
      },
      {
        path: "festivals/:parentId/sponsors/:id/edit",
        name: "PageModeratorFestivalFestivalSponsorEdit",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalSponsorEdit.vue"
          ),
        meta: {
          title: "Edit Sponsor",
        },
      },
      {
        path: "festivals/:parentId/sponsors/:id",
        name: "PageModeratorFestivalFestivalSponsorRead",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalSponsorRead.vue"
          ),
        meta: {
          title: "Sponsor Info",
        },
      },

      {
        path: "festivals/:parentId/sponsors/:id/delete",
        name: "PageModeratorFestivalFestivalSponsorDelete",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalSponsorDelete.vue"
          ),
        meta: {
          title: "Delete Sponsor",
        },
      },
      {
        path: "festivals/:parentId/prizes/new",
        name: "PageModeratorFestivalFestivalPrizeAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorFestivalFestivalPrizeAdd.vue"),
        meta: {
          title: "New Prize",
        },
      },
      {
        path: "festivals/:parentId/prizes/:id/edit",
        name: "PageModeratorFestivalFestivalPrizeEdit",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalPrizeEdit.vue"
          ),
        meta: {
          title: "Edit Prize",
        },
      },
      {
        path: "festivals/:parentId/prizes/:id",
        name: "PageModeratorFestivalFestivalPrizeRead",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalPrizeRead.vue"
          ),
        meta: {
          title: "Prize Info",
        },
      },

      {
        path: "festivals/:parentId/prizes/:id/delete",
        name: "PageModeratorFestivalFestivalPrizeDelete",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalFestivalPrizeDelete.vue"
          ),
        meta: {
          title: "Delete Prize",
        },
      },
      {
        path: "festivals/:parentId/events/new",
        name: "PageModeratorFestivalEventOnFestivalAdd",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorFestivalEventOnFestivalAdd.vue"
          ),
        meta: {
          title: "Assign event",
        },
      },
      {
        path: "classrooms/:parentId/events/new",
        name: "PageModeratorClassroomEventOnClassroomAdd",
        props: true,
        component: () =>
          import(
            "@/views/moderator/PageModeratorClassroomEventOnClassroomAdd.vue"
          ),
        meta: {
          title: "New event",
        },
      },
      {
        path: "schools",
        name: "PageModeratorSchoolBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSchoolBrowse.vue"),
        meta: {
          title: "Schools",
        },
      },
      {
        path: "schools/new",
        name: "PageModeratorSchoolAdd",
        props: true,
        component: () => import("@/views/moderator/PageModeratorSchoolAdd.vue"),
        meta: {
          title: "New School",
        },
      },
      {
        path: "schools/quickSetup",
        name: "PageModeratorSchoolQuickSetup",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSchoolQuickSetup.vue"),
        meta: {
          title: "New School",
        },
      },
      {
        path: "schools/:id/edit",
        name: "PageModeratorSchoolEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSchoolEdit.vue"),
        meta: {
          title: "Edit School",
        },
      },
      {
        path: "schools/:id/delete",
        name: "PageModeratorSchoolDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSchoolDelete.vue"),
        meta: {
          title: "Delete School",
        },
      },
      {
        path: "schools/:id",
        name: "PageModeratorSchoolRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSchoolRead.vue"),
        meta: {
          title: "School Info",
        },
      },
      {
        path: "teachers",
        name: "PageModeratorTeacherBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherBrowse.vue"),
        meta: {
          title: "Teachers",
        },
      },
      {
        path: "teachers/new",
        name: "PageModeratorTeacherAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherAdd.vue"),
        meta: {
          title: "New Teacher",
        },
      },
      {
        path: "teachers/:id",
        name: "PageModeratorTeacherRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherRead.vue"),
        meta: {
          title: "Teacher Info",
        },
      },
      {
        path: "teachers/:id/delete",
        name: "PageModeratorTeacherDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherDelete.vue"),
        meta: {
          title: "Delete Teacher",
        },
      },
      {
        path: "teachers/:id/edit",
        name: "PageModeratorTeacherEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherEdit.vue"),
        meta: {
          title: "Edit Teacher",
        },
      },
      {
        path: "teachers/:id/transfer",
        name: "PageModeratorTeacherTransfer",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorTeacherTransfer.vue"),
        meta: {
          title: "Transfer Teacher",
        },
      },
      {
        path: "accounts",
        name: "PageModeratorAccountBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountBrowse.vue"),
        meta: {
          title: "accounts",
        },
      },
      {
        path: "accounts/new",
        name: "PageModeratorAccountAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountAdd.vue"),
        meta: {
          title: "New account",
        },
      },
      {
        path: "accounts/:id",
        name: "PageModeratorAccountRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountRead.vue"),
        meta: {
          title: "Account Info",
        },
      },
      {
        path: "accounts/:id/edit",
        name: "PageModeratorAccountEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountEdit.vue"),
        meta: {
          title: "Edit Account",
        },
      },
      {
        path: "accounts/:id/delete",
        name: "PageModeratorAccountDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountDelete.vue"),
        meta: {
          title: "Delete Account",
        },
      },
      {
        path: "accounts/:id/restore",
        name: "PageModeratorAccountRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountRestore.vue"),
        meta: {
          title: "Restore Account",
        },
      },
      {
        path: "accounts/:parentId/user/new",
        name: "PageModeratorAccountUserAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountUserAdd.vue"),
        meta: {
          title: "New User",
        },
      },
      {
        path: "accounts/:parentId/user/:id",
        name: "PageModeratorAccountUserRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountUserRead.vue"),
        meta: {
          title: "User Info",
        },
      },
      {
        path: "accounts/:parentId/user/:id/edit",
        name: "PageModeratorAccountUserEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountUserEdit.vue"),
        meta: {
          title: "Edit User",
        },
      },
      {
        path: "accounts/:parentId/institution/new",
        name: "PageModeratorAccountInstitutionAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountInstitutionAdd.vue"),
        meta: {
          title: "New Institution",
        },
      },
      {
        path: "accounts/:parentId/institution/assign",
        name: "PageModeratorAccountInstitutionAssign",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountInstitutionAssign.vue"),
        meta: {
          title: "Assign Institution",
        },
      },
      {
        path: "accounts/:parentId/institution/:id",
        name: "PageModeratorAccountInstitutionRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountInstitutionRead.vue"),
        meta: {
          title: "Institution Info",
        },
      },
      {
        path: "accounts/:parentId/classroom/:id",
        name: "PageModeratorAccountClassroomRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountClassroomRead.vue"),
        meta: {
          title: "Classroom Info",
        },
      },
      {
        path: "accounts/:grandParentId/classroom/:parentId/student/:id",
        name: "PageModeratorAccountClassroomStudentRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorAccountClassroomStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "students",
        name: "PageModeratorStudentBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorStudentBrowse.vue"),
        meta: {
          title: "Students",
        },
      },
      {
        path: "students/:id",
        name: "PageModeratorStudentRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "students/:id/edit",
        name: "PageModeratorStudentEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorStudentEdit.vue"),
        meta: {
          title: "Edit Student",
        },
      },
      {
        path: "news",
        name: "PageModeratorNewsBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorNewsBrowse.vue"),
        meta: {
          title: "News",
        },
      },
      {
        path: "news/new",
        name: "PageModeratorNewsAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorNewsAdd.vue"),
        meta: {
          title: "New News",
        },
      },
      {
        path: "news/:id",
        name: "PageModeratorNewsRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorNewsRead.vue"),
        meta: {
          title: "News Info",
        },
      },
      {
        path: "news/:id/edit",
        name: "PageModeratorNewsEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorNewsEdit.vue"),
        meta: {
          title: "Edit News",
        },
      },
      {
        path: "news/:id/delete",
        name: "PageModeratorNewsDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorNewsDelete.vue"),
        meta: {
          title: "Delete News",
        },
      },
      {
        path: "themes",
        name: "PageModeratorThemeBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorThemeBrowse.vue"),
        meta: {
          title: "Themes",
        },
      },
      {
        path: "themes/new",
        name: "PageModeratorThemeAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorThemeAdd.vue"),
        meta: {
          title: "New Theme",
        },
      },
      {
        path: "themes/:id",
        name: "PageModeratorThemeRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorThemeRead.vue"),
        meta: {
          title: "Theme Info",
        },
      },
      {
        path: "themes/:id/edit",
        name: "PageModeratorThemeEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorThemeEdit.vue"),
        meta: {
          title: "Edit Theme",
        },
      },
      {
        path: "themes/:id/manageDrawing",
        name: "PageModeratorThemeWorkshopDrawingManage",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorThemeWorkshopDrawingManage.vue"),
        meta: {
          title: "Manage Drawing",
        },
      },
      {
        path: "players",
        name: "PageModeratorPlayerBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerBrowse.vue"),
        meta: {
          title: "Players",
        },
      },
      {
        path: "players/new",
        name: "PageModeratorPlayerAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerAdd.vue"),
        meta: {
          title: "New Player",
        },
      },
      {
        path: "players/:id",
        name: "PageModeratorPlayerRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerRead.vue"),
        meta: {
          title: "Player Info",
        },
      },
      {
        path: "players/:id/delete",
        name: "PageModeratorPlayerDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerDelete.vue"),
        meta: {
          title: "Delete Player",
        },
      },
      {
        path: "players/:id/deleteHard",
        name: "PageModeratorPlayerDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerDeleteHard.vue"),
        meta: {
          title: "Delete Player",
        },
      },
      {
        path: "players/:id/restore",
        name: "PageModeratorPlayerRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerRestore.vue"),
        meta: {
          title: "Restore Player",
        },
      },
      {
        path: "players/:id/edit",
        name: "PageModeratorPlayerEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerEdit.vue"),
        meta: {
          title: "Edit Player",
        },
      },
      {
        path: "players/:parentId/inventory/:id",
        name: "PageModeratorPlayerInventoryRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerInventoryRead.vue"),
        meta: {
          title: "Inventory Info",
        },
      },
      {
        path: "players/:parentId/inventory/:id/sell",
        name: "PageModeratorPlayerInventorySell",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerInventorySell.vue"),
        meta: {
          title: "Sell Inventory",
        },
      },
      {
        path: "dailyGameProgress",
        name: "PageModeratorPlayerDailyGameProgressBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPlayerDailyGameProgressBrowse.vue"),
        meta: {
          title: "Daily Game Players",
        },
      },
      {
        path: "institutions",
        name: "PageModeratorInstitutionBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionBrowse.vue"),
        meta: {
          title: "Institutions",
        },
      },
      {
        path: "institutions/new",
        name: "PageModeratorInstitutionAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionAdd.vue"),
        meta: {
          title: "New Institution",
        },
      },
      {
        path: "institutions/migrate",
        name: "PageModeratorInstitutionMigrate",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionMigrate.vue"),
        meta: {
          title: "New Institution",
        },
      },
      {
        path: "institutions/:id",
        name: "PageModeratorInstitutionRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionRead.vue"),
        meta: {
          title: "Institution Info",
        },
      },
      {
        path: "institutions/:id/delete",
        name: "PageModeratorInstitutionDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionDelete.vue"),
        meta: {
          title: "Delete Institution",
        },
      },
      {
        path: "institutions/:id/deleteHard",
        name: "PageModeratorInstitutionDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionDeleteHard.vue"),
        meta: {
          title: "Delete Institution",
        },
      },
      {
        path: "institutions/:id/restore",
        name: "PageModeratorInstitutionRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionRestore.vue"),
        meta: {
          title: "Restore Institution",
        },
      },
      {
        path: "institutions/:id/edit",
        name: "PageModeratorInstitutionEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionEdit.vue"),
        meta: {
          title: "Edit Institution",
        },
      },
      {
        path: "institutions/:parentId/teacher/new",
        name: "PageModeratorInstitutionTeacherAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherAdd.vue"),
        meta: {
          title: "New Teacher",
        },
      },
      {
        path: "institutions/:parentId/teacher/changeAdmin",
        name: "PageModeratorInstitutionTeacherChangeAdmin",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherChangeAdmin.vue"),
        meta: {
          title: "Change Admin",
        },
      },
      {
        path: "institutions/:parentId/teacher/:id",
        name: "PageModeratorInstitutionTeacherRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherRead.vue"),
        meta: {
          title: "Teacher Info",
        },
      },
      {
        path: "institutions/:parentId/teacher/:id/edit",
        name: "PageModeratorInstitutionTeacherEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherEdit.vue"),
        meta: {
          title: "Edit Teacher",
        },
      },
      {
        path: "institutions/:parentId/teacher/:id/delete",
        name: "PageModeratorInstitutionTeacherDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherDelete.vue"),
        meta: {
          title: "Delete Teacher",
        },
      },
      {
        path: "institutions/:parentId/teacher/:id/deleteHard",
        name: "PageModeratorInstitutionTeacherDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherDeleteHard.vue"),
        meta: {
          title: "Delete Teacher",
        },
      },
      {
        path: "institutions/:parentId/teacher/:id/restore",
        name: "PageModeratorInstitutionTeacherRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionTeacherRestore.vue"),
        meta: {
          title: "Restore Teacher",
        },
      },
      {
        path: "institutions/:parentId/classroom/new",
        name: "PageModeratorInstitutionClassroomAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomAdd.vue"),
        meta: {
          title: "New Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/migrate",
        name: "PageModeratorInstitutionClassroomMigrate",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomMigrate.vue"),
        meta: {
          title: "Migrate Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id",
        name: "PageModeratorInstitutionClassroomRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomRead.vue"),
        meta: {
          title: "Classroom Info",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/edit",
        name: "PageModeratorInstitutionClassroomEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomEdit.vue"),
        meta: {
          title: "Edit Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/delete",
        name: "PageModeratorInstitutionClassroomDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomDelete.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/inactive",
        name: "PageModeratorInstitutionClassroomInactive",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomInactive.vue"),
        meta: {
          title: "Inactive Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/deleteAllStudents",
        name: "PageModeratorInstitutionClassroomDeleteAllStudents",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomDeleteAllStudents.vue"),
        meta: {
          title: "Delete all Students",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/promote",
        name: "PageModeratorInstitutionClassroomPromote",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomPromote.vue"),
        meta: {
          title: "Promote Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/deleteHard",
        name: "PageModeratorInstitutionClassroomDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomDeleteHard.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "institutions/:parentId/classroom/:id/restore",
        name: "PageModeratorInstitutionClassroomRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomRestore.vue"),
        meta: {
          title: "Restore Classroom",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/new",
        name: "PageModeratorInstitutionClassroomStudentAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentAdd.vue"),
        meta: {
          title: "New Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/import",
        name: "PageModeratorInstitutionClassroomStudentImport",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentImport.vue"),
        meta: {
          title: "Add Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/transfer",
        name: "PageModeratorInstitutionClassroomStudentTransfer",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentTransfer.vue"),
        meta: {
          title: "Import Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id",
        name: "PageModeratorInstitutionClassroomStudentRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/move",
        name: "PageModeratorInstitutionClassroomStudentMove",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentMove.vue"),
        meta: {
          title: "Move Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/link",
        name: "PageModeratorInstitutionClassroomStudentLink",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentLink.vue"),
        meta: {
          title: "Link Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/edit",
        name: "PageModeratorInstitutionClassroomStudentEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentEdit.vue"),
        meta: {
          title: "Edit Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/delete",
        name: "PageModeratorInstitutionClassroomStudentDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentDelete.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/deleteHard",
        name: "PageModeratorInstitutionClassroomStudentDeleteHard",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentDeleteHard.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "institutions/:grandParentId/classroom/:parentId/student/:id/restore",
        name: "PageModeratorInstitutionClassroomStudentRestore",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorInstitutionClassroomStudentRestore.vue"),
        meta: {
          title: "Restore Student",
        },
      },
      {
        path: "account/changePassword",
        name: "PageModeratorPasswordChange",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorPasswordChange.vue"),
        meta: {
          title: "Change password",
        },
      },
      {
        path: "sponsors",
        name: "PageModeratorSponsorBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSponsorBrowse.vue"),
        meta: {
          title: "Sponsors",
        },
      },
      {
        path: "sponsors/new",
        name: "PageModeratorSponsorAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSponsorAdd.vue"),
        meta: {
          title: "New Sponsor",
        },
      },
      {
        path: "sponsors/:id",
        name: "PageModeratorSponsorRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSponsorRead.vue"),
        meta: {
          title: "Sponsor Info",
        },
      },
      {
        path: "sponsors/:id/delete",
        name: "PageModeratorSponsorDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSponsorDelete.vue"),
        meta: {
          title: "Delete Sponsor",
        },
      },
      {
        path: "sponsors/:id/edit",
        name: "PageModeratorSponsorEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorSponsorEdit.vue"),
        meta: {
          title: "Edit Sponsor",
        },
      },
      {
        path: "jobs",
        name: "PageModeratorJobBrowse",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorJobBrowse.vue"),
        meta: {
          title: "Jobs",
        },
      },
      {
        path: "jobs/new",
        name: "PageModeratorJobAdd",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorJobAdd.vue"),
        meta: {
          title: "New Job",
        },
      },
      {
        path: "jobs/:id",
        name: "PageModeratorJobRead",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorJobRead.vue"),
        meta: {
          title: "Job Info",
        },
      },
      {
        path: "jobs/:id/delete",
        name: "PageModeratorJobDelete",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorJobDelete.vue"),
        meta: {
          title: "Delete Job",
        },
      },
      {
        path: "jobs/:id/edit",
        name: "PageModeratorJobEdit",
        props: true,
        component: () =>
          import("@/views/moderator/PageModeratorJobEdit.vue"),
        meta: {
          title: "Edit Job",
        },
      },
    ],
  },
  {
    path: "/account",
    component: LayoutMain,
    beforeEnter: multiguard([loggedIn]),
    children: [
      {
        path: "",
        name: "PageAccountMyAccount",
        component: () => import("@/views/account/PageAccountMyAccount.vue"),
        meta: {
          title: "My Account",
        },
      },
      {
        path: "/changePassword",
        name: "PageAccountChangePassword",
        component: () => import("@/views/account/PageAccountChangePassword.vue"),
        meta: {
          title: "Change Password",
        },
      },
      {
        path: "/changeMobile",
        name: "PageAccountChangeMobile",
        component: () => import("@/views/account/PageAccountChangeMobile.vue"),
        meta: {
          title: "Change Mobile",
        },
      },
    ],
  },
  {
    path: "/family",
    component: LayoutMain,
    beforeEnter: multiguard([loggedIn, isFamily]),
    children: [
      {
        path: "dashboard",
        name: "PageFamilyDashboard",
        component: () => import("@/views/family/PageFamilyDashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "owner/add",
        name: "PageFamilyOwnerAdd",
        component: () => import("@/views/family/PageFamilyOwnerAdd.vue"),
        beforeEnter: multiguard([isChild]),
        meta: {
          title: "New Owner",
        },
      },
      {
        path: "user/add",
        name: "PageFamilyUserAdd",
        component: () => import("@/views/family/PageFamilyUserAdd.vue"),
        meta: {
          title: "New User",
        },
      },
      {
        path: "user/:id",
        name: "PageFamilyUserRead",
        component: () => import("@/views/family/PageFamilyUserRead.vue"),
        meta: {
          title: "User Info",
        },
      },
      {
        path: "user/:id/edit",
        name: "PageFamilyUserEdit",
        component: () => import("@/views/family/PageFamilyUserEdit.vue"),
        meta: {
          title: "Edit User",
        },
      },
      {
        path: "user/:id/delete",
        name: "PageFamilyUserDelete",
        component: () => import("@/views/family/PageFamilyUserDelete.vue"),
        meta: {
          title: "Delete User",
        },
      },
      {
        path: "group",
        name: "PageFamilyGroup",
        component: () => import("@/views/family/PageFamilyGroup.vue"),
        meta: {
          title: "Group",
        },
      },
    ],
  },
  {
    path: "/educator",
    component: LayoutMain,
    beforeEnter: multiguard([loggedIn, isEducator, isChangedPassword, isVerifiedMobile]),
    children: [
      {
        path: "dashboard",
        name: "PageEducatorDashboard",
        component: () => import("@/views/educator/PageEducatorDashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "user/add",
        name: "PageEducatorUserAdd",
        component: () => import("@/views/educator/PageEducatorUserAdd.vue"),
        meta: {
          title: "New User",
        },
      },
      {
        path: "classroom/add",
        name: "PageEducatorClassroomAdd",
        component: () =>
          import("@/views/educator/PageEducatorClassroomAdd.vue"),
        meta: {
          title: "New Classroom",
        },
      },
      {
        path: "institution/add",
        name: "PageEducatorInstitutionAdd",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionAdd.vue"),
        meta: {
          title: "New Institution",
        },
      },
      {
        path: "user/:id",
        name: "PageEducatorUserRead",
        component: () => import("@/views/educator/PageEducatorUserRead.vue"),
        meta: {
          title: "User Info",
        },
      },
      {
        path: "user/:id/edit",
        name: "PageEducatorUserEdit",
        component: () => import("@/views/educator/PageEducatorUserEdit.vue"),
        meta: {
          title: "Edit User",
        },
      },
      {
        path: "user/:id/delete",
        name: "PageEducatorUserDelete",
        component: () => import("@/views/educator/PageEducatorUserDelete.vue"),
        meta: {
          title: "Delete User",
        },
      },
      {
        path: "classroom/:id",
        name: "PageEducatorClassroomDashboard",
        component: () =>
          import("@/views/educator/PageEducatorClassroomDashboard.vue"),
        meta: {
          title: "Classroom Dashboard",
        },
      },
      {
        path: "classroom/:id/info",
        name: "PageEducatorClassroomRead",
        component: () =>
          import("@/views/educator/PageEducatorClassroomRead.vue"),
        meta: {
          title: "Classroom Info",
        },
      },
      {
        path: "classroom/:id/edit",
        name: "PageEducatorClassroomEdit",
        component: () =>
          import("@/views/educator/PageEducatorClassroomEdit.vue"),
        meta: {
          title: "Edit Classroom",
        },
      },
      {
        path: "classroom/:id/delete",
        name: "PageEducatorClassroomDelete",
        component: () =>
          import("@/views/educator/PageEducatorClassroomDelete.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "classroom/:id/student",
        name: "PageEducatorClassroomStudent",
        component: () =>
          import("@/views/educator/PageEducatorClassroomStudent.vue"),
        meta: {
          title: "Classroom",
        },
      },
      {
        path: "classroom/:parentId/student/new",
        name: "PageEducatorClassroomStudentAdd",
        component: () =>
          import("@/views/educator/PageEducatorClassroomStudentAdd.vue"),
        meta: {
          title: "Student Add",
        },
      },
      {
        path: "classroom/:parentId/student/:id/info",
        name: "PageEducatorClassroomStudentRead",
        component: () =>
          import("@/views/educator/PageEducatorClassroomStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "classroom/:parentId/student/:id/delete",
        name: "PageEducatorClassroomStudentDelete",
        component: () =>
          import("@/views/educator/PageEducatorClassroomStudentDelete.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "classroom/:parentId/student/:id/edit",
        name: "PageEducatorClassroomStudentEdit",
        component: () =>
          import("@/views/educator/PageEducatorClassroomStudentEdit.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "institution/:id",
        name: "PageEducatorInstitutionDashboard",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionDashboard.vue"),
        meta: {
          title: "Institution Dashboard",
        },
      },
      {
        path: "institution/:id/info",
        name: "PageEducatorInstitutionRead",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionRead.vue"),
        meta: {
          title: "Institution Info",
        },
      },
      {
        path: "institution/:id/edit",
        name: "PageEducatorInstitutionEdit",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionEdit.vue"),
        meta: {
          title: "Edit Institution",
        },
      },
      {
        path: "institution/:id/delete",
        name: "PageEducatorInstitutionDelete",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionDelete.vue"),
        meta: {
          title: "Delete Institution",
        },
      },
      {
        path: "application/:id/read",
        name: "PageEducatorInstitutionApplicationRead",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionApplicationRead.vue"),
        meta: {
          title: "Institution Info",
        },
      },
      {
        path: "application/:id/edit",
        name: "PageEducatorInstitutionApplicationEdit",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionApplicationEdit.vue"),
        meta: {
          title: "Edit Application",
        },
      },
      {
        path: "application/:id/cancel",
        name: "PageEducatorInstitutionApplicationCancel",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionApplicationCancel.vue"
          ),
        meta: {
          title: "Cancel Application",
        },
      },
      {
        path: "institution/:id/classroom",
        name: "PageEducatorInstitutionClassroom",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroom.vue"),
        meta: {
          title: "Classrooms",
        },
      },
      {
        path: "institution/:id/inactiveClassroom",
        name: "PageEducatorInstitutionInactiveClassroom",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionInactiveClassroom.vue"),
        meta: {
          title: "Inactive Classrooms",
        },
      },
      {
        path: "institution/:parentId/classroom/new",
        name: "PageEducatorInstitutionClassroomAdd",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroomAdd.vue"),
        meta: {
          title: "Classroom Add",
        },
      },
      {
        path: "institution/:parentId/classroom/:id",
        name: "PageEducatorInstitutionClassroomDashboard",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomDashboard.vue"
          ),
        meta: {
          title: "Classroom Dashboard",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/info",
        name: "PageEducatorInstitutionClassroomRead",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroomRead.vue"),
        meta: {
          title: "Classroom Info",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/inactive",
        name: "PageEducatorInstitutionClassroomInactive",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroomInactive.vue"),
        meta: {
          title: "Inactive Classroom",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/edit",
        name: "PageEducatorInstitutionClassroomEdit",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroomEdit.vue"),
        meta: {
          title: "Edit Classroom",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/delete",
        name: "PageEducatorInstitutionClassroomDelete",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionClassroomDelete.vue"),
        meta: {
          title: "Delete Classroom",
        },
      },
      {
        path: "institution/:id/event",
        name: "PageEducatorInstitutionEvent",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionEvent.vue"),
        meta: {
          title: "Events",
        },
      },
      {
        path: "institution/:id/teacher",
        name: "PageEducatorInstitutionTeacher",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacher.vue"),
        meta: {
          title: "Teachers",
        },
      },
      {
        path: "institution/:id/teacher/new",
        name: "PageEducatorInstitutionTeacherAdd",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacherAdd.vue"),
        meta: {
          title: "New Teacher",
        },
      },
      {
        path: "institution/:id/teacher/changeAdmin",
        name: "PageEducatorInstitutionTeacherChangeAdmin",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacherChangeAdmin.vue"),
        meta: {
          title: "Change Admin",
        },
      },
      {
        path: "institution/:parentId/teacher/:id",
        name: "PageEducatorInstitutionTeacherRead",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacherRead.vue"),
        meta: {
          title: "Teacher Info",
        },
      },
      {
        path: "institution/:parentId/teacher/:id/edit",
        name: "PageEducatorInstitutionTeacherEdit",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacherEdit.vue"),
        meta: {
          title: "Edit Teacher",
        },
      },
      {
        path: "institution/:parentId/teacher/:id/delete",
        name: "PageEducatorInstitutionTeacherDelete",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionTeacherDelete.vue"),
        meta: {
          title: "Delete Teacher",
        },
      },
      {
        path: "institution/:id/student",
        name: "PageEducatorInstitutionStudent",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionStudent.vue"),
        meta: {
          title: "Students",
        },
      },
      {
        path: "institution/:id/student/new",
        name: "PageEducatorInstitutionStudentAdd",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionStudentAdd.vue"),
        meta: {
          title: "New Student",
        },
      },
      {
        path: "institution/:parentId/student/:id",
        name: "PageEducatorInstitutionStudentRead",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionStudentRead.vue"),
        meta: {
          title: "Student Info",
        },
      },
      {
        path: "institution/:parentId/student/:id/edit",
        name: "PageEducatorInstitutionStudentEdit",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionStudentEdit.vue"),
        meta: {
          title: "Edit Student",
        },
      },
      {
        path: "institution/:parentId/student/:id/delete",
        name: "PageEducatorInstitutionStudentDelete",
        component: () =>
          import("@/views/educator/PageEducatorInstitutionStudentDelete.vue"),
        meta: {
          title: "Delete Student",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/student",
        name: "PageEducatorInstitutionClassroomStudent",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudent.vue"
          ),
        meta: {
          title: "Students",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/inactiveStudent",
        name: "PageEducatorInstitutionClassroomInactiveStudent",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomInactiveStudent.vue"
          ),
        meta: {
          title: "Inactive Students",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/student/new",
        name: "PageEducatorInstitutionClassroomStudentAdd",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentAdd.vue"
          ),
        meta: {
          title: "Student Add",
        },
      },
      {
        path: "institution/:parentId/classroom/:id/student/import",
        name: "PageEducatorInstitutionClassroomStudentImport",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentImport.vue"
          ),
        meta: {
          title: "Student Add",
        },
      },
      {
        path: "institution/:grandparentId/classroom/:parentId/student/:id/info",
        name: "PageEducatorInstitutionClassroomStudentRead",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentRead.vue"
          ),
        meta: {
          title: "Student Add",
        },
      },
      {
        path: "institution/:grandparentId/classroom/:parentId/student/:id/edit",
        name: "PageEducatorInstitutionClassroomStudentEdit",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentEdit.vue"
          ),
        meta: {
          title: "Edit Student",
        },
      },
      {
        path: "institution/:grandparentId/classroom/:parentId/student/:id/move",
        name: "PageEducatorInstitutionClassroomStudentMove",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentMove.vue"
          ),
        meta: {
          title: "Move Student",
        },
      },
      {
        path: "institution/:grandparentId/classroom/:parentId/student/:id/delete",
        name: "PageEducatorInstitutionClassroomStudentDelete",
        component: () =>
          import(
            "@/views/educator/PageEducatorInstitutionClassroomStudentDelete.vue"
          ),
        meta: {
          title: "Edit Student",
        },
      },
    ],
  },
  {
    path: "/educator",
    component: LayoutMain,
    beforeEnter: multiguard([loggedIn, isEducator]),
    children: [
      {
        path: "verify",
        name: "PageEducatorAccountVerify",
        component: () =>
          import("@/views/educator/PageEducatorAccountVerify.vue"),
        meta: {
          title: "Account verify",
        },
      },
      {
        path: "onboard/password",
        name: "PageEducatorOnboardPassword",
        component: () =>
          import("@/views/educator/PageEducatorOnboardPassword.vue"),
        meta: {
          title: "Onboarding - Change Password",
        },
      },
      {
        path: "onboard/telegram",
        name: "PageEducatorOnboardTelegram",
        component: () =>
          import("@/views/educator/PageEducatorOnboardTelegram.vue"),
        meta: {
          title: "Onboarding - Subscribe Telegram",
        },
      },
    ],
  },
  {
    path: "",
    component: LayoutBlank,
    children: [
      {
        path: "system/connection",
        name: "PageSystemConnection",
        component: () => import("@/views/system/PageSystemConnection.vue"),
      },
      {
        path: "system/fix",
        name: "PageSystemFix",
        component: () =>
          import("@/views/system/PageSystemFix.vue"),
      },
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () => import("@/views/error/PageErrorSessionExpired.vue"),
      },
      {
        path: "error/restricted",
        name: "PageErrorAccessRestricted",
        component: () => import("@/views/error/PageErrorAccessRestricted.vue"),
      },
      {
        path: "error/construction",
        name: "PageErrorUnderConstruction",
        component: () => import("@/views/error/PageErrorUnderConstruction.vue"),
      },
      {
        path: "error/maintenance",
        name: "PageErrorUnderMaintenance",
        component: () => import("@/views/error/PageErrorUnderMaintenance.vue"),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () => import("@/views/error/PageErrorNotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: locale,
  routes,
});

//BOC:[page title]
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title + " | Hamochi" : "Hamochi";
  });
});
//EOC

export default router;

export default {
  action: {
    assign_classroom:"Assign classroom",
    assign_event:"Assign Event",
    assign_other_school:"Assign other school classroom",
    assign_school_year:"Assign school year",
    assign_school:"Assign school",
    back:"Back",
    cancel: 'Cancel',
    click_here:"Click Here",
    confirm: "Confirm",
    copy:"Copy",
    copied:"Copied!",
    delete:"Delete",
    download:"Download",
    download_excel:"Download Excel",
    download_image:"Download Image",
    edit:"Edit",
    generate:"Generate",
    generate_pass:"Generate Express Pass",
    info: "Info",
    join: "Join Now",
    login: 'Login',
    login_now: "Login Now",
    logout:"Log Out",
    manage:"Manage",
    manage_pass:"Manage Express Pass",
    manage_prize:"Manage prize",
    manage_questions:"Manage Questions",
    new_classroom:"New classroom",
    new_event:"New event",
    new_festival: "New Festival",
    new_institution: "New Institution",
    new_school:"New school",
    new_sponsor: "New sponsor",
    new_student:"New student",
    new_teacher: "New teacher",
    next:"Next",
    no:"No",
    ok:"Ok",
    orderBy: "Order By",
    play: "Play",
    playAgain: "Play Again",
    previous:"Previous",
    print:"Print",
    quick_setup:"Quick Setup",
    regenerateQuestion: "Regenerate Questions",
    remove:"Remove",
    replace: "Replace",
    replaceImage: "Replace Image",
    returnHome: "Return to Home",
    save:"Save",
    search: "Search",
    select:"Select",
    selected:"Selected",
    send_invitation:"Send Invitation",
    send_notice:"Send Notice",
    send_pass:"Send Pass",
    set_password:"Set Password",
    scoreboard:"Scoreboard",
    share:"Share",
    start: "Start",
    testPlay: "Play",
    view: "View",
    viewMore:"View More",
    viewSample: "View Sample",
    view_templates:"View Templates",
    whatsapp: "Whatsapp Us",
    yes:"Yes",
  },
  api: {
    response: {
      no_data: "No data available.",
    },
  },
  model: {
    filterBy: {
      all: "All",
    },
    name: {
      account:"Account",
      accounts:"Accounts",
      activity:"Activity",
      application:"Application",
      applications:"Applications",
      attempts:"Attempts",
      building:"Building",
      buildings:"Buildings",
      chapter: "Chapter",
      chapters: "Chapters",
      checkpoint: "Question",
      checkpoints: "Questions",
      classroom: "Classroom",
      classrooms: "Classrooms",
      dailyGameProgress: "Daily Game Progress",
      dashboard:"Dashboard",
      event: "Event",
      events: "Events",
      // festival:"Festival",
      // festivals:"Festivals",
      finisher:"Finisher",
      finishers:"Finishers",
      game:"Game",
      institution: "Institution",
      institutions: "Institutions",
      inventory:"Inventory",
      inventories:"Inventories",
      job: "Job",
      jobs: "Jobs",
      labRecord: "Note",
      labRecords: "Notes",
      member:"Member",
      members:"Members",
      news:"News",
      participant: "Participant",
      participants: "Participants",
      performance:"Performance",
      player:"Player",
      players:"Players",
      prize: "Prize",
      prizes: "Prizes",
      record: "Record",
      records: "Records",
      rewards:"Rewards",
      rule:"Rule",
      rules:"Rules",
      series:"Series",
      topic:"Topic",
      topics:"Topics",
      question: "Question",
      questions: "Questions",
      school: "School",
      schools: "Schools",
      schoolyear:"School Year",
      schoolyears:"School Years",
      sponsor:"Sponsor",
      sponsors:"Sponsors",
      student: "Student",
      students: "Students",
      submission: "Submission",
      submissions: "Submissions",
      syllabus: "Syllabus",
      syllabuses: "Syllabuses",
      teachers:"Teachers",
      teacher:"Teacher",
      theme:"Theme",
      themes:"Themes",
      user:"User",
      users:"Users",
      winner: "Winner",
      winners: "Winners",
    },
    prop: {
      action:"Action",
      accuracy: "Accuracy",
      attendance:"Attendance",
      attempt: "Attempts",
      code:"code",
      corrects: "Corrects",
      count:"Count",
      created:"Created",
      data:"Data",
      dateEnd: "End Date",
      dateStart: "Start Date",
      description:"Description",
      emblem: "School Emblem",
      end:"End",
      finishers:"Finishers",
      fullname: "Full Name",
      gameUrl:"Game Url",
      lastUpdated:"Last Updated",
      logo:"Logo",
      message:"Message",
      mobile: 'Mobile Number',
      name: "Name",
      nameClassroom:"Classroom Name",
      nameEvent:"Event Name",
      nameSchool:"School Name",
      no: "#",
      participants: "Participants",
      password: 'Password',
      progress:"Progress",
      quantity: "Quantity",
      questions:"Questions",
      gender:"Gender",
      isActive: "Active",
      itemName: "Item",
      subtitle:"Subtitle",
      schoolCode:"School Code: ",
      schoolYear:"School Year",
      score: "Score",
      stars: "Stars",
      start:"Start",
      status: "Status",
      students:"Students",
      studentCode:"Student Code: ",
      submissions:"Submissions",
      time: "Time",
      timeEnd:"Time End",
      timeStart:"Time Start",
      timestampEnd: "End",
      timestampStart: "Start",
      total:"Total",
      totalAttempt: "Total Attempts",
      totalSubmission:"Total Submissions",
      totalScore: "Total Score",
      totalStar: "Total Stars",
      town:"Town",
      tried:"Tried",
      weakness: "Weakness",
      websiteUrl:"Website Url",
      year:"Year",
    },
    value: {
      status: {
        absent: "Absent",
        active:"Active",
        coming: "Upcoming",
        completed: "Completed",
        disable:"Disable",
        enable:"Enable",
        inactive:"Inactive",
        joined: "Joined",
        ongoing: "Ongoing",
        past: "Past",
      },
    },
  },
  route: {
    PageMainAccountLogin: 'Account Login',
    PageMainApply: "Application for Hamochi Edu-Cup 2024/2025",
    PageMainDiscover: "Question Bank",
    PageMainDiscoverQuiz: "Quiz",
    PageMainDiscoverSample: "Sample Questions",
    PageMainEvent: "Events",
    PageMainEventAnalysis: "Analysis",
    PageMainEventFollowup: "Follow Up",
    PageMainEventGuide: "Guide",
    PageMainEventScoreboard: "Scoreboard",
    PageMainHome: 'Home',
    PageMainLanguage: "Change Language",
    PageMainLogin: 'Login',
    PageMainRegister: 'Register',
  },
  rules: {
    classroom:"Classroom is required",
    confirm_password:"Confirm Password is required",
    count:"Count is required",
    event:"Event is required",
    general:"Please check the form.",
    logo:"Image size should be less than 2 MB!",
    min8Characters:"Min 8 characters.",
    mobile:"Mobile number has to be between 7 and 15",
    mobileNumber:"The Mobile number must be an integer number",
    name: "Name is required",
    nameClassroom:"Classroom name is required",
    nameEvent:"Event name is required",
    nameSchool:"School name is required",
    new_password:"New Password is required",
    notBlank:"Please fill in.",
    old_password:"Old Password is required",
    password:"Password must be 4 characters",
    passwordMustMatch:"Passwords must match.",
    town:"town is required",
    websiteUrl:"Website Url is required",
  },
  counter_suffix:{
    duration_day: "Day",
    duration_hour: "Hour",
    duration_minute: "Min",
    duration_second: "Sec",
    time_day: "Day",
    time_hour: "Hour",
    time_minute: "Min",
    time_second: "Sec",
  },
  string: {
    bread_assign:"Assign ",
    bread_manage:"Manage ",
    bread_new:"New ",
    bread_template:"Template",
    error_construction:"Coming soon.",
    event_past: "Past Events",
    express_classroom: "Express Classroom",
    family:"Family",
    info:"Info",
    info_institution: "Institution Info",
    loading:"Loading...",
    menu:"Menu",
    my:"My ",
    my_institution:"My Institution",
    page:"Page",
    pick_logo:"Pick a logo",
    presented_by:"Presented by",
    question_prefix: "Q",
    sample_question: "Sample Question",
    schoolYear_prefix:"Year",
    schoolYear_suffix:"",
    self_registration:"Self Registration",
    search_by_name:"Search by name",
    step:"Step",
    to:"To",
  },
  view: {
    PageBackendClassroom:{
      assign_event_title:"Assign Event",
      self_registration:"Self Registration",
    },
    PageBackendClassroomExpress: {
      express_pass_count: "Express Pass Count",
      generate_pass_title: "Generate New Express Pass",
      manage_pass_title: "Manage Express Pass",
    },
    PageBackendEvent:{
      assign_classroom:"Assign Classroom",
      assign_school_year:"Assign School Year",
      add_new_title:"New Event",
      edit_title:"Edit Event",
      image:"Image",
      invitation:"Invitation",
      manage_prize:"Manage prize",
      message:"Message",
      notice:"Notice",
      pass:"Pass",
      scoreboard:"Scoreboard",
      send_event_notice:"Send Event Notice",
      send_pass:"Send Pass",
      send_scoreboard:"Send Scoreboard",
      send_syllabus:"Send syllabus",
      set_password:"Set Password",
      template:"Template",
      template_name:"Template Name"
    },
    PageBackendPasswordChange: {
      confirm_password:"Confirm Password",
      new_password:"New Password",
      old_password: "Old Password",
      password_change_title: "Change Password",
    },
    PageBackendSchool:{
      invitation:"Invitation",
      quick_setup_title:"Quick Setup New School"
    },
    PageBackendStudent:{
      add_new_1_description:"One student per line.",
      add_new_2_description:"You may copy and paste the name from excel file.",
      add_new_3_description:'Our system will auto convert "L" to "Male" and "F" to "Female".',
      add_new_title:"New Students",
      step:"Step",
    },
    PageBackendTeacher:{
      add_new_title: "New Teacher",
      edit_title: "Edit Teacher",
    },
    PageErrorNotFound: {
      title: "Oops...",
      description: "Page not found.",
    },
    PageErrorSessionExpired: {
      title: "Your session has expired.",
      description: "Please log in again.",
    },
    PageMainDiscoverQuiz: {
      my_entry: "My Entry",
      my_score: "My Result",
      points: "Points",
      secs: "Secs",
      time: "Time",
      total_questions: "Total 10 questions.",
    },
    PageMainEventScoreboardReport:{
      comment:{
        high:"Great",
        low:"Low",
      },
      header:{
        accuracy:"Accuracy",
        avgCorrect:"Average Correct",
        avgQuestion:"Average Question",
        comment:"Remark",
        event:"Event",
        school:"School",
        topic:"Topic",
      },
      improvement:{
        totalImprovedStudent: "Total of students who improved"
      },
      progress:{
        collectedAllCollections: "Collected all collections",
        completedTutorial:"Completed tutorial",
        createdAvatar: "Created avatar",
        finishedAllTopics:"Finished all topics",
        joined: "Joined event",
        playedOneLevel:"Played one level",
        purchasedApparel:"Purchased apparel",
        registered:"Registered account",
      },
      stat:{
        students:"Total Students",
        participants:"Total Participants",
        finishers:"Total Finishers",
        attendance:"Rate of Participation",
        completed:"Rate of Completion",
        submissions:"Total Submissions",
        questions:"Total Questions",
        corrects:"Total Corrects",
        accuracy:"Accuracy",
        averageQuestion:"Average question per participant",
      },
    },
    PageMainEventScoreboard: {
      countdown_end: "Ends in...",
      countdown_ended: "Ended on ",
      countdown_start: "Starts in...",
      countdown_time_days: "DAY",
      countdown_time_hours: "HRS",
      countdown_time_minutes: "MIN",
      countdown_time_seconds: "SEC",
      event_finisher_protection_title:"This section is protected.",
      event_finisher_protection_description:"Please enter the code.",
      event_finisher_protection_failed_title:"Access denied.",
      event_finisher_protection_failed_description:"Please enter the correct code.",
      event_finisher_status_all: "All",
      event_finisher_status_completed: "Finished",
      event_finisher_status_incomplete: "Not yet finish",
      event_pdf_total_finisher:"Total of Finishers:",
      event_pdf_total_winner:'Total of Winners:',
      event_pdf_total_winner_by_school:'Total Winners by School Years:',
      event_pdf_top5_winner:'Top 5 Winners by School Years:',
      event_pdf_winner_list:'List of Winners:',
      highlight_title:"Today Highlight",
      highlight_1_shout:"Wow, lots of fishes!",
      highlight_2_shout:"Here i come!",
      highlight_1_content:"catches tons of fishes and is leading.",
      highlight_2_content:"catches lots of butterflies and is leading.",
      perfect_score:"Perfect score.",
      report:"Report",
    },
    PageMainEventScoreboardStudent:{
      missing_attempt:" of the submissions are deleted. The system delete expired data automatically.",
    },
    PageMainFestival:{
      password_title:"Enter School Code",
      password_label:"School Code",
    },
    PageMainFestivalRead:{
      analysis:"Analysis",
      join_error_title:"Oops...",
      join_error_description:"Event not yet started. It shall start on ",
      protection_title:"This festival is protected.",
      protection_description:"Please enter School Code.",
      protection_action:"Enter Code",
      protection_error:"School code is missing.",
      protection_failed_title:"Access denied.",
      protection_failed_description:"Please enter the correct code.",
      protection_failed_error:"School code is wrong.",
      sponsor_description:"Thank you for the generous sponsorship.",
      tab_guide:"General",
      tab_ranking:"Ranking",
      tab_student_allStudent:"All Students",
      tab_student_top3:"Top 3",
      tab_topic:"Topics",
    },
    PageMainHome: {
      slide_client_title: 'Collaborations',
      slide_client_button_more: 'Show More',
      slide_contact_title: 'Need help?',
      slide_contact_cta: 'Live Support',
      slide_event_title:"Events",
      slide_faq_title: 'Frequently Asked Questions (FAQ)',
      slide_faq_1_question: "Do we have to pay?",
      slide_faq_1_answer: "No. It is FREE.",
      slide_faq_2_question: "What platforms does it supports?",
      slide_faq_2_answer: "It supports both desktop and mobile.",
      slide_faq_3_question: "Does it supports HuaWei phone?",
      slide_faq_3_answer: "Yes, so long it has internet browser.",
      slide_faq_4_question: "Does it supports Google Gradebook?",
      slide_faq_4_answer: "Currently no. We may consider if we received more requests. Inform us if you are interested in this feature and we will update you when it is available.",
      slide_faq_5_question: "Does it covers subjects other than Mathematics?",
      slide_faq_5_answer: "Currently no. We may consider if we received more requests. Inform us if you are interested in this feature and we will update you when it is available.",
      slide_faq_6_question: "Can teacher change the topics?",
      slide_faq_6_answer: "Yes. We have a list of topics to be chosen.",
      slide_faq_7_question: "How to make sure students key in their names correctly?",
      slide_faq_7_answer: "We have optional pre-registration feature that allows teacher to setup student accounts in advance.",
      slide_faq_8_question: "How to prevent students copy the answers?",
      slide_faq_8_answer: "Everytime a student answering the questions, random questions are generated on the spot by AI hence everytime the questions will be different.",
      slide_faq_9_question: "What if a parent helps the student to answer?",
      slide_faq_9_answer: "Our solution is similar to usual homework assignments. It requires teacher to inform parents not to intervene for the sake of the students.",
      slide_feature_title: "Features",
      slide_feature_1_title: 'Effortless',
      slide_feature_1_description: 'Questions are auto generated.',
      slide_feature_2_title: "Educational",
      slide_feature_2_description: 'Reinforce learning.',
      slide_feature_3_title: 'Entertaining',
      slide_feature_3_description: 'Appealing to young children.',
      slide_game_title:"Try out the demo!",
      slide_game_description:"FREE. No registration required.",
      slide_game_button_play:"PLAY NOW",
      slide_game_image_caption:"New fishing game event is available from April to Jun 2022",
      slide_performance_title: "",
      slide_performance_description: "",
      slide_performance_1: "",
      slide_performance_2: "",
      slide_performance_3: "",
      slide_performance_4: "",
      slide_performance_5: "",
      slide_performance_6: "",
      slide_performance_7: "",
      slide_pitch_title: 'No. 1 EduSports',
      slide_pitch_description: 'Gamify school events',
      slide_pitch_button_host: 'How to host ?',
      slide_point_title: "",
      slide_point_1: "",
      slide_point_2: "",
      slide_point_3: "",
      slide_point_4: "",
      slide_point_5: "",
      slide_point_6: "",
      slide_point_7: "",
      slide_social_hint_educator: "For Educators",
      slide_social_hint_follow: "Follow us on social media for latest developments.",
      slide_social_hint_maker: "For Creators",
      slide_syllabus_title: 'Question Bank',
      slide_syllabus_name1: 'KSSR Semakan Year 1 Mathematics',
      slide_testimony_title: 'What Our Users Said',
      slide_testimony_1_name: "SJK(C) Kampung Merbau, Principal Tia Phaik Wan",
      slide_testimony_1_message: "\"After few discussions with Math teachers, we successfully introduced Hamochi to the students. In addition to the usual boring online lessons, this solution allows our students to have the opportunity to enjoy learning mathematics during Covid-19 pandemic easily. We had our first online contest with prizes from Jun 21 to 26, 2021. Thank you Edison and his team in founding Hamochi that gave our students the chance to participate. 🙏 Truly grateful for all your efforts. 🥰 \" (translated from Mandarin)",
      slide_testimony_2_name: "Wonder Kids Daycare Centre Kuantan, Principal Lim",
      slide_testimony_2_message: "\"This solution allows my students to do revision anywhere anytime, saved a lots of my time. The report is clear, no need to mark the submitted works. Besides challenging each others, my students are able to view and break their own record. After played repeatedly, their memories become better and more long-lasting. Like 👍. \" (translated from Mandarin)",
      slide_testimony_3_name: "SJK(C) Hwa Lian 2, Principal Hu Ee Ling",
      slide_testimony_3_message: "\"Thank you Edison and hamochi for hosting and providing us the opportunity to participate in the Mathematics gaming event. hamochi is an activity that allows our students to learn while playing, it also raised their interests in Mathematics. This game is capable of planting thinking habbit into our students, makes them learn and love Mathematics. Thank you. \" (translated from Mandarin)",
      slide_testimony_4_name: "SJK(C) Hwa Lian 1, Principal Fong Yin Leng",
      slide_testimony_4_message: "\"... When I tried it for the first time, my four years old son is sitting besides of me excitedly. He urged me to finish all the Mathematics questions so he can see the ending. I think this game is very great, it attracted both children and parents, making parents willing to accompany their children in learning. When I completed the game, my son asked me to play one more time. From that point, I know this interactive game can make my students learn and becomes interested in Mathematics...\"(translated from Mandarin)",
      slide_testimony_parent_title: "Parents' Testimonials",
      slide_video_title: "Introduction",
      slide_v3_pitch_title: "Hamochi Edu-Cup 2024/2025",
      slide_v3_pitch_description_1: "Let's turn learning into a habit.",
      slide_v3_pitch_description_2: "The most exciting school holiday educational activity for primary students.",
      slide_v3_pitch_description_3: "The registration of this year events is open now. <b class=\"primary--text\">84 schools registered.</b>",
      slide_v3_pitch_button: "Register Now!",
    },
    PageMainLogin: {
      student_title: "I'm a student",
      teacher_title: "I'm a teacher",
    },
    PageMainPage:{
      220901:{
        schools:"",
        sponsors:"",
      },
    },
    PageMainRegister: {
      title: "Register for School",
      description: "Our team will help schools to setup all related account and host the contests.",
      message: "Hi%20i%20am%20interested%20to%20register%20hamochigames%20for%20my%20school.",
      form:"https://docs.google.com/forms/d/e/1FAIpQLSedBC-j7KvlMnPfxoAUEYGalb1sFF4bgeb--8ZKUyJ7PlhpyQ/viewform?embedded=true",
    },
    PagePerformance:{
      no_items:"No Items",
      performance_chart:"Chart",
      performance_table:"Table",
      star_calculation_message:" less than 10 questions OR less than 60%"
    },
  },
  log :{
    'submission/add':"Submit answers",
    'player/add':"Player account created",
    'player/login':"Log in",
  },
}